import Skeleton from 'react-loading-skeleton'
import { Asset } from 'contentful'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { base, modularScale } from 'bl-common/src/constants/sizes'
import { Button } from 'bl-common/src/elements/Button/Button'
import { buttonPadding } from 'bl-common/src/elements/Button/styles'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import {
  Type,
  typeFontSize,
} from 'bl-common/src/elements/Typography/Typography'
import { IListItem } from 'bl-common/src/generated/contentful'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { theme } from 'bl-common/src/styles/theme'
import { between } from 'bl-common/src/utils/between'
import { mediaMax, mediaObj } from 'bl-common/src/utils/media'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { interpolatePrice } from 'bl-utils/src/currency/interpolatePrice'
import { replaceLineBreaks } from 'bl-utils/src/replaceLineBreaks'

export type AdmissionCardProps = {
  title: string
  buttonText: string
  admissionStatus?: 'available' | 'fullyBooked' | 'loading'
  onClick(): void
  subtitle?: string
  image?: Asset
  listItems?: Pick<IListItem, 'fields'>[]
  hasChildren?: boolean
  label?: string
  children?: React.ReactNode
  currency?: string
  exchangeRates?: Record<string, number>
  cardTheme?: { background?: string; color?: string }
  disabled?: boolean
}

const Container = styled.button<{
  cardTheme: AdmissionCardProps['cardTheme']
}>(({ cardTheme }) => ({
  width: '100%',
  backgroundColor: colors.white,
  background: cardTheme?.background,
  color: cardTheme?.color || colors.midGrey,
  textAlign: 'start',
  boxShadow: '0px 0px 30px rgb(0 0 0 / 15%)',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  cursor: 'pointer',
  flex: '0 1 auto',

  ':disabled': {
    cursor: 'default',
  },

  minHeight: 370,

  [mediaObj.lg]: {
    minHeight: 'auto',
  },
}))

const ImageContainer = styled.div({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: theme.spacing[20],
  position: 'relative',
  width: '100%',

  [mediaObj.md]: {
    height: theme.spacing[15],
  },
})

const Content = styled.div<{ hasImage?: boolean }>(({ hasImage }) => ({
  padding: `${hasImage ? theme.spacing[2] : theme.spacing[4]} ${
    theme.spacing[2.5]
  } ${theme.spacing[4]} ${theme.spacing[2.5]}`,
  width: '100%',

  [mediaObj.smd]: {
    padding: `${hasImage ? theme.spacing[2] : theme.spacing[3]} ${
      theme.spacing[2]
    } ${theme.spacing[2]} ${theme.spacing[2]}`,
  },
}))

const Top = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: theme.spacing[10],
  justifyContent: 'space-between',
  marginBottom: theme.spacing[2],
  whiteSpace: 'pre-line',
})

const ListItem = styled.li({
  display: 'flex',
  gap: theme.spacing[0.5],
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flex: '0 0 0',
  flexWrap: 'nowrap',
  width: '100%',
  flexFlow: 'row nowrap',
})

const List = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing[1],
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})

const IconWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
  width: `calc(${theme.spacing[1]} * .8)`,
  height: `calc(${theme.spacing[1]} * .8)`,

  svg: {
    marginTop: 6,
    height: between((base / modularScale) * 0.7, base * 0.7),
    width: between((base / modularScale) * 0.7, base * 0.7),

    [`@media (min-width: ${breakpoints.md}px)`]: {
      marginTop: `calc(${theme.spacing[0.5]} * .45)`,
    },
  },
})

// Leaving out commented in case we need bullet points again.
// const NormalBulletPoint = styled.div({
//   width: `calc(${theme.spacing[0.5]} * .8)`,
//   height: `calc(${theme.spacing[0.5]} * .8)`,
//   borderRadius: '50%',
//   backgroundColor: colors.deepBlue,
//   marginTop: 7,

//   [`@media (min-width: ${breakpoints.md}px)`]: {
//     marginTop: `calc(${theme.spacing[1]} * .35)`,
//   },
// })

const PlusIcon = () => (
  <svg viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke={colors.deepBlue}
      strokeWidth="2"
      strokeLinecap="round"
      d="M5 1v7M8.5 4.5h-7"
    />
  </svg>
)

const TextWrapper = styled.div({
  flex: 1,
  alignSelf: 'flex-start',
})

const LabelWrapper = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  color: colors.midGrey,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1,
})

const Label = styled.div`
  text-align: center;
  background-color: ${colors.blueOnDark50};
  padding: ${theme.spacing[0.5]} ${theme.spacing[2]};
  border-radius: 0rem 0rem 0.9375rem 0.9375rem;
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`

const ButtonSkeleton = styled(Skeleton)`
  ${() => buttonPadding.medium()}
  ${() => typeFontSize({ xs: 14, md: 16 })}
  transition: none;
  margin-bottom: 2px;
  margin-top: 1px;

  ${mediaMax.md(css`
    font-size: 14px;
  `)};
`

export const SIGNATURE_BACKGROUND =
  'linear-gradient(317.02deg, rgba(216, 231, 230, 0.5) 25.64%, rgba(245, 246, 245, 0.5) 117.84%)'

export const AdmissionCard = ({
  title,
  admissionStatus,
  subtitle,
  listItems,
  onClick,
  buttonText,
  label,
  children,
  image,
  currency = 'ISK',
  exchangeRates = { ISK: 1 },
  cardTheme,
  disabled,
}: AdmissionCardProps) => {
  const { mediaBmd } = useBreakpoints()
  const hasImage = !!image && !mediaBmd
  const isFullyBooked = admissionStatus === 'fullyBooked'
  const isLoading = admissionStatus === 'loading'

  // If the prices are loading the subtitle is an array of string, skeleton, string
  // because of that we both need to replace the \n if subtitle is a string and for each string within the array if it's an array
  // If we don't do that, the skeleton is in the wrong line
  const subtitleText = Array.isArray(subtitle)
    ? subtitle?.map(item =>
        typeof item === 'string' ? replaceLineBreaks(item) : item
      )
    : typeof subtitle === 'string'
    ? replaceLineBreaks(subtitle)
    : subtitle

  return (
    <Container
      onClick={onClick}
      disabled={isFullyBooked || isLoading || disabled}
      type="button"
      cardTheme={cardTheme}
    >
      {label && (
        <LabelWrapper>
          <Label>
            <Type preset="textSmall" weight="bold">
              {label}
            </Type>
          </Label>
        </LabelWrapper>
      )}
      {hasImage && (
        <ImageContainer>
          <ContentfulImage alt={title} image={image} />
        </ImageContainer>
      )}
      <Content hasImage={hasImage}>
        <Top>
          <div>
            <Type preset="headlineMedium" bottom={{ xs: 1, md: 1 }}>
              {title}
            </Type>

            {/* The 'notranslate' className below is required to avoid
            the screen from crashing when it's translated with Google Translate */}
            {subtitle && (
              <Type
                preset="text"
                bottom={{ xs: 1.5, md: 2 }}
                weight={isFullyBooked ? 'normal' : 'bold'}
                className="notranslate"
                color={isFullyBooked ? colors.grey : undefined}
              >
                {subtitleText}
              </Type>
            )}
          </div>
          {isLoading ? (
            <ButtonSkeleton />
          ) : (
            <Button
              as="div"
              noHover={isFullyBooked}
              style={{
                cursor: isFullyBooked ? 'default' : 'pointer',
              }}
              preset={isFullyBooked ? 'lightGrey' : 'blue'}
              textColor={isFullyBooked ? 'shuttleGrey' : 'white'}
            >
              {buttonText}
            </Button>
          )}
        </Top>
        {children && children}
        <List>
          {listItems?.map(item => (
            <ListItem key={item.fields.item}>
              {item.fields.itemStyle === 'Additional' && (
                <IconWrapper>
                  <PlusIcon />
                </IconWrapper>
              )}
              <TextWrapper>
                <Type
                  preset="textSmall"
                  weight={item.fields.itemStyle === 'Title' ? 'bold' : 'normal'}
                >
                  {item.fields.price
                    ? interpolatePrice(
                        [
                          formatPrice(
                            calcPrice(
                              item.fields.price,
                              exchangeRates?.[currency]
                            ),
                            currency,
                            false
                          ),
                        ],
                        item.fields.item
                      )
                    : item.fields.item}
                </Type>
              </TextWrapper>
            </ListItem>
          ))}
        </List>
      </Content>
    </Container>
  )
}
