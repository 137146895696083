import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { useBookingSessionContext } from 'bl-common/src/context/BookingSessionContext'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { buildFlow, FlowRootSetup } from 'bl-flows-core'
import { AdmissionItem, CartItemType } from 'bl-graphql'
import {
  calcPrice,
  calcPriceWithFractions,
} from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { getMembershipProductId } from 'bl-utils/src/membership'
import { DayVisitProductId, PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { useCartExpiration } from '../../hooks/useCartExpiration'
import { useRequireCartItems } from '../../hooks/useRequireCartItems'
import { globalBookingMessages } from '../../messages'
import {
  SPA_CART_EXPIRATION_MS,
  SPA_CART_EXPIRATION_WARNING_MS,
} from './constants'
import { admissionScreen } from './screens/admissionScreen'
import { bookingDetailsScreen } from './screens/bookingDetailsScreen'
import { calendarScreen } from './screens/calendarScreen'
import { extrasScreen } from './screens/extras'
import { extrasTransportationScreen } from './screens/extrasTransportation'
import { paymentDetailsScreen } from './screens/paymentDetails'
import { spaGuestScreen } from './screens/spaGuestScreen'
import { timeScreen } from './screens/timeScreen'

const confirmationPaths = {
  en: '/book/spa/confirmation',
  is: '/is/boka/spa/stadfesting',
}

const allowedScreenIndexWithoutCartItems = 3

type PackageType = 'comfort' | 'premium' | 'signature' | 'subscription'

const setupFlow: FlowRootSetup = (_, control) => {
  const router = useRouter()
  const {
    cart,
    cartId,
    checkoutPayment,
    loading,
    error,
    resetCart,
    addProductCartItem,
    addSpaCustomerInfo,
    removeItemFromCart,
    addPromoCode,
    addGiftCard,
    removeGiftCard,
    createCart,
    cartLastUpdated,
  } = useCartContext()

  const [admissionItem, setAdmissionItem] = useState<AdmissionItem>(null)
  const { setPreviousConfirmedCart } = useBookingSessionContext()
  const { currency, exchangeRates } = useContext(CurrencyContext)
  const useMCPPayment = control.context.useMCPPayment

  const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(
    null
  )

  const [confirmationDrawerProductId, setConfirmationDrawerProductId] =
    useState<DayVisitProductId | null>(null)

  const { mediaBmd: isBookingEngineSplitScreen } = useBreakpoints()

  const calculatedPrice = useMCPPayment
    ? calcPriceWithFractions(
        cart?.paymentAmount ?? 0,
        exchangeRates?.[currency]
      )
    : calcPrice(cart?.paymentAmount || 0, exchangeRates?.[currency])

  const formattedPrice = useMemo(
    () => formatPrice(calculatedPrice, currency, false),
    [currency, cart?.paymentAmount, exchangeRates]
  )

  // Fetch the selected package from URL
  const selectedPackageSlug = useMemo(() => {
    if (cart?.items?.length > 0) {
      if (
        cart.items.find(
          item =>
            item.productId === PRODUCT_IDS.BLWinterCard ||
            item.productId === PRODUCT_IDS.BLWinterCardFamily ||
            item.productId === PRODUCT_IDS.BLSummerCard ||
            item.productId === PRODUCT_IDS.BLSummerCardFamily
        )
      ) {
        return 'subscription'
      }
      if (cart.items.find(item => item.productId === PRODUCT_IDS.SpaPremium)) {
        return 'premium'
      }
      if (cart.items.find(item => item.productId === PRODUCT_IDS.SpaComfort)) {
        return 'comfort'
      }
      if (
        cart.items.find(item => item.productId === PRODUCT_IDS.SpaSignature)
      ) {
        return 'signature'
      }
    }

    return window?.location?.pathname?.indexOf('/premium/') > 0
      ? 'premium'
      : window?.location?.pathname?.indexOf('/comfort/') > 0
      ? 'comfort'
      : window?.location?.pathname?.indexOf('/signature/') > 0
      ? 'signature'
      : cart?.membership == null
      ? 'comfort'
      : 'subscription' // Winter & summer card?
  }, [cart?.items, router.asPath, cart?.membership])

  const changePackageSlug = useCallback((packageType: PackageType) => {
    setSelectedPackage(packageType)
  }, [])

  useEffect(() => {
    control.flow.setState({
      previousPath:
        control.context.locale === 'is'
          ? '/is/heimsokn/blaa-lonid'
          : '/day-visit/the-blue-lagoon',
    })
  }, [control.context.locale])

  useEffect(() => {
    if (cart?.customer) {
      const { __typename, ...primaryGuest } = cart.customer
      control.flow.setStateWithoutValidation({
        information: {
          ...control.flow.state?.information,
          primaryGuest: { ...primaryGuest },
        },
      })
    }
  }, [cart?.customer])

  // When subscription is removed on the time step, make sure to reset the selected package
  // otherwise the user will see no availability
  // We also want to reset the agreeToTerms and primaryGuest fields
  useEffect(() => {
    if (selectedPackage === 'subscription' && cart?.membership == null) {
      setSelectedPackage(selectedPackageSlug)
      control.flow.setStateWithoutValidation({
        information: {
          ...control.flow.state?.information,
          agreeToTerms: false,
          primaryGuest: null,
        },
      })
    }
  }, [cart?.membership, selectedPackageSlug, selectedPackage])

  useEffect(() => {
    if (cart?.items) {
      const admissionItemsInCart = cart.items.filter(
        item => item.type === CartItemType.Admission
      ) as AdmissionItem[]
      if (admissionItemsInCart && admissionItemsInCart.length > 0) {
        setAdmissionItem(admissionItemsInCart[0])

        // If the user has a cart with admission items, we want to update the number of guests
        // In some cases people were messing with the URL which affected the number of guests e.g. in the bus transport flow.
        const { adults, children } = admissionItemsInCart.reduce(
          (counts, item) => {
            if (item.productId === PRODUCT_IDS.SpaChild) {
              counts.children += item.qty
            } else {
              counts.adults += item.qty
            }
            return counts
          },
          { adults: 0, children: 0 }
        )

        control.flow.setState({
          guests: {
            adults,
            children,
          },
        })
      }
    }
  }, [cart?.items])

  return {
    router,
    selectedPackage: selectedPackage || selectedPackageSlug,
    cart,
    cartId,
    checkoutPayment,
    loading,
    error,
    resetCart,
    createCart,
    addProductCartItem,
    isMembershipInCart: cart?.membership != null,
    membershipProductId:
      cart?.membership != null ? getMembershipProductId(cart.membership) : null,
    admissionItem,
    currency,
    exchangeRates,
    setPreviousConfirmedCart,
    changePackageSlug,
    addPromoCode,
    addSpaCustomerInfo,
    formattedPrice,
    addGiftCard,
    removeGiftCard,
    isBookingEngineSplitScreen,
    setSelectedPackage,
    removeItemFromCart,
    cartLastUpdated,
    isSPA: true,
    confirmationDrawerProductId,
    setConfirmationDrawerProductId,
  }
}

export const spaBookingFlow = buildFlow({
  id: 'spaBookingFlow',
  routerSettings: {
    updateHistory: true,
  },
  setupHook: setupFlow,
  watchHook: (_, control) => {
    const { cart, createCart } = useCartContext()
    const resetSession = useCallback(async () => {
      if (cart?.items?.length === 0) {
        return
      }
      sentryLogging({ message: 'Cart expiration: Expired modal was opened' })
      control.reset({
        previousPath: control.flow.state.previousPath,
      })
      control.confirm(
        control.context.t(globalBookingMessages.text.sessionTimeoutSubtitle),
        {
          title: control.context.t(
            globalBookingMessages.text.sessionTimeoutTitle
          ),
          confirmLabel: control.context.t(
            globalBookingMessages.buttons.continue
          ),
        }
      )
      await createCart({ force: true })
    }, [cart])

    // We must have cart items past screen index 2.
    // Screen index 3 is the extras screen
    useRequireCartItems(control, allowedScreenIndexWithoutCartItems, {
      previousPath: control.flow.state.previousPath,
    })

    // Expire the cart if it's inactive for a certain amount of time
    useCartExpiration({
      onExpired: resetSession,
      expirationTimeInMs: SPA_CART_EXPIRATION_MS,
      expirationWarningTimeInMs: SPA_CART_EXPIRATION_WARNING_MS,
      warningModal: useCallback(() => {
        sentryLogging({
          message: 'Cart expiration: Warning modal was opened',
        })
        return control.confirm(
          control.context.t(
            globalBookingMessages.text.sessionTimeoutWarningSubtitle
          ),
          {
            title: control.context.t(
              globalBookingMessages.text.sessionTimeoutWarningTitle
            ),
            confirmLabel: control.context.t(
              globalBookingMessages.buttons.continue
            ),
          }
        )
      }, []),
    })
  },
  children: [
    spaGuestScreen(),
    calendarScreen({ screenTheme: 'default' }),
    admissionScreen({ screenTheme: 'default' }),
    timeScreen({ screenTheme: 'default' }),
    extrasScreen({ screenTheme: 'default' }),
    extrasTransportationScreen({ screenTheme: 'default' }),
    bookingDetailsScreen({ screenTheme: 'default', confirmationPaths }),
    paymentDetailsScreen({ screenTheme: 'default', confirmationPaths }),
  ],
})
